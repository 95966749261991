import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import MessageItem from "../components/MessageItem";
import {BookmarkIcon, CheckCircleIcon, DocumentTextIcon, PaperAirplaneIcon} from "@heroicons/react/16/solid";
import {Message, Role, Workflow} from "../types";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {getToken} from "../utils/authentication";
import {UserContext} from "../App";

const sendMessage = async (
    session_id: string,
    document_context: string,
    messages: { role: string; content: string }[],
) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + "/content-converter",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                    "Cache-Control": "no-cache",
                    Connection: "keep-alive",
                },
                body: JSON.stringify({
                    session_id,
                    document_context,
                    messages,
                }),
            },
        );
        if (response.ok) {
            const data = await response.json();
            console.log("Antwort erhalten:", data);
            return [data.answer, data.sources];
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Abrufen der Dokumente:", error);
    }
};

const archiveConversation = async (
    documentTitle: string,
    messages: { role: string; content: string }[],
) => {
    try {
        const currentDate = new Date().toLocaleString("de-DE");

        const archiveTitle = `Konversation | ${documentTitle} | ${currentDate}`

        const archiveData = {
            title: archiveTitle,
            history: JSON.stringify(messages),
            sharedWithTenant: false,
        };

        const response = await fetch(process.env.REACT_APP_API_URL + "/archives", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + getToken(),
            },
            body: JSON.stringify(archiveData),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Archivierung erfolgreich:", data);
            return data;
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Archivieren der Konversation:", error);
    }
};

const ContentConverter: React.FC = () => {
    const navigate = useNavigate();
    const conversationEndRef = useRef<HTMLDivElement>(null);

    const [sessionId, setSessionId] = useState<string>(uuidv4());
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string>("");
    const [workflows, setWorkflows] = useState<Workflow[]>([]);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const [documentContext, setDocumentContext] = useState<string>("");
    const [documentTitle, setDocumentTitle] = useState<string>("");

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const {currentUser} = useContext(UserContext);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/workflows",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setWorkflows(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, []);

    useEffect(() => {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
    }, []);

    const handleWorkflow = async (workflow: Workflow) => {
        const newMessages = [
            ...messages,
            {
                role: "system",
                content: workflow.context + "\n \n" + workflow.action,
                sources: [],
            },
        ];

        setMessages(newMessages);
        conversationEndRef.current?.scrollIntoView({behavior: "smooth"});
        setLoading(true);

        const response = await sendMessage(
            sessionId,
            documentContext,
            newMessages,
        );
        handleResponse(response);
    };

    const handleMessage = async () => {
        if (userInput === "") return;

        const newMessages = [
            ...messages,
            {role: "user", content: userInput, sources: []},
        ];

        setMessages(newMessages);
        conversationEndRef.current?.scrollIntoView({behavior: "smooth"});
        setUserInput("");
        setLoading(true);

        const response = await sendMessage(
            sessionId,
            documentContext,
            newMessages,
        );
        handleResponse(response);
    };

    const handleResponse = (response: any) => {
        if (response) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {role: "assistant", content: response[0], sources: response[1]},
            ]);
            conversationEndRef.current?.scrollIntoView({behavior: "smooth"});

            setLoading(false);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    useEffect(() => {
        if (selectedFile) {
            handleUpload();
        }
    }, [selectedFile]);

    const handleUpload = async () => {
        if (!selectedFile) return;

        setUploading(true);

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/document-parser`,
                {
                    method: "POST",
                    headers: {
                        "Authorization": 'Bearer ' + getToken(),
                    },
                    body: formData,
                },
            );
            if (response.ok) {
                const data = await response.json();
                // console.log("Document parsed:", data);
                setDocumentContext(data.extracted_text);
                setDocumentTitle(data.file_name);
                setUploadSuccess(true);
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setUploading(false);
            setSelectedFile(null);
        }
    };

    const handleArchiveClick = async () => {
        const archivedData = await archiveConversation(
            documentTitle,
            messages
        );
        if (archivedData) {
            setMessages([]);
            setUserInput("");
            setSessionId(uuidv4());
            navigate("/archives");
        }
    };

    return (
        <>
            <div>
                <h2 className="text-lg font-semibold text-center mx-2 mb-4 leading-6 text-gray-900">
                    Dokument wählen
                </h2>
                <p className="mt-1 mb-6 text-sm font-medium text-center text-gray-500">
                    Hier kann ein Dokument in wenigen Schritten in einen MILES-Kurs konvertiert werden.
                </p>

                <div className="col-span-full bg-white rounded-2xl p-4 mb-10">
                    {uploading && (
                        <div className="flex row justify-between">
                            <ArrowPathIcon className="animate-spin h-6 w-6 text-blue-600 mr-4"/>
                        </div>
                    )}
                    {documentContext ? (
                        <div className="flex">
                            <CheckCircleIcon
                                className="mr-3 h-5 w-5 text-green-600"
                                aria-hidden="true"
                            />
                            <span
                                className="text-sm font-normal text-green-600">
                                Der Kontext des Dokuments <span className="font-bold">{documentTitle}</span> ist verfügbar.
                            </span>
                        </div>
                    ) : (
                        <div className="flex justify-center rounded-2xl px-6 py-2">
                            <div>
                                <DocumentTextIcon
                                    className="mx-auto h-12 w-12 text-gray-300"
                                    aria-hidden="true"
                                />
                                <div className="flex justify-center mt-4 text-sm leading-6 text-gray-600">
                                    <label
                                        htmlFor={`file-upload`}
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600"
                                    >
                                        <span>PDF, DOCX, PPTX oder TXT hochladen</span>
                                        <input
                                            id={`file-upload`}
                                            name="file-upload"
                                            type="file"
                                            className="sr-only"
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                </div>
                                {errorMessage && (
                                    <div className="mt-6 text-red-500 text-sm text-center">{errorMessage}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <hr className="z-10"/>
            </div>
            <div>
                <h2 className="text-lg font-semibold text-center mx-2 mb-4 leading-6 text-gray-900">
                    Konversation
                </h2>
                <p className="mt-1 text-sm font-medium text-center text-gray-500">
                    Für vordefinierte Anwendungsfälle können Workflows genutzt werden
                </p>
                <div className="mt-6 mb-72">
                    <div>
                        {messages?.map(
                            (message, i) =>
                                message.role !== "system" && (
                                    <MessageItem {...message} key={i}/>
                                ),
                        )}
                    </div>

                    {messages?.length >= 1 && (
                        <button
                            className="flex justify-center items-center mx-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-blue-600 hover:text-blue-700 font-medium cursor-pointer"
                            onClick={handleArchiveClick}
                        >
                            <BookmarkIcon className="w-4 h-4 mr-2"></BookmarkIcon>
                            archivieren und beenden
                        </button>
                    )}
                </div>
                <div ref={conversationEndRef}/>
            </div>

            <div className="fixed bottom-0 left-0 right-0 flex justify-center">
                <div className="py-4 px-3 backdrop-blur-md bg-gray-200/50 w-full max-w-screen-md rounded-xl">
                    {documentContext && (
                        <div className="w-full flex flex-wrap justify-start">
                            {workflows?.map(
                                (workflow, i) =>
                                    (workflow.visibleForConverter && !workflow.isDisabled && (!workflow.isTemplate || currentUser?.role === Role.SuperAdmin)) && (
                                        <div
                                            onClick={() => handleWorkflow(workflow)}
                                            className={`py-2 px-3 mx-1 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer ${
                                                workflow.isTemplate ? 'hover:bg-gray-600 bg-gray-500' : 'hover:bg-blue-700 bg-blue-600'
                                            }`}
                                            key={i}
                                        >
                                            {workflow.title}
                                        </div>
                                    ),
                            )}
                        </div>
                    )}

                    <form>
                        <div
                            className={`flex items-center py-2 rounded-xl ${
                                loading || !documentContext ? "bg-gray-300" : "bg-white"
                            } mt-2`}
                        >
              <textarea
                  id="chat"
                  value={userInput}
                  className="block mx-2 p-2.5 w-full h-26 text-md text-gray-900 bg-transparent rounded-xl font-medium border-none focus:ring-0 focus:ring-offset-0"
                  placeholder="Prompt …"
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={(e) => {
                      if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
                          handleMessage();
                      }
                  }}
                  disabled={loading || !documentContext}
              ></textarea>
                            <div
                                className="inline-flex justify-center p-2 mr-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100">
                                {loading ? (
                                    <span
                                        className="animate-ping mr-1 w-7 h-7 rounded-full bg-blue-600 opacity-60"></span>
                                ) : (
                                    <PaperAirplaneIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                        onClick={handleMessage}
                                    />
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContentConverter;
