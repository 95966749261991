import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Tenant} from "../types"; // Assuming you have defined a Tenant type
import {PlusIcon} from "@heroicons/react/16/solid";
import {getToken} from "../utils/authentication";

const Tenants: React.FC = () => {
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "/tenants", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTenants(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchTenants();
    }, []);

    if (loading) {
        return <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>;
    }

    if (errorMessage) {
        return <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>;
    }

    return (
        <div>
            <div className="flex justify-between">
                <div>
                    <h2 className="text-lg font-semibold text-left mb-4 leading-6 text-gray-900">
                        Tenants verwalten
                    </h2>
                    <p className="mt-1 text-sm font-medium text-left text-gray-500">
                        Gruppiere Benutzer mithilfe von Tenants
                    </p>
                </div>
                <Link to="/tenants/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600">
                        <PlusIcon className="w-4 h-4 mr-1"/>
                        Tenant erstellen
                    </button>
                </Link>
            </div>

            <hr className="z-10 mb-8"/>

            <div className="overflow-x-auto rounded-xl">
                <table className="min-w-full divide-y divide-gray-200 bg-white border-separate rounded-xl">
                    <thead>
                    <tr>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">ID</th>
                        <th className="px-5 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">Name</th>
                        <th className="px-5 py-3 text-right text-xs font-medium text-gray-900 uppercase tracking-wider">Aktionen</th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {tenants.map((tenant) => (
                        <tr key={tenant.id}>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{tenant.id}</td>
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{tenant.name}</td>
                            <td className="px-5 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <Link to={`/tenants/edit/${tenant.id}`}
                                      className="text-blue-600 hover:text-blue-900">bearbeiten</Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Tenants;
