import {useNavigate} from 'react-router-dom';
import {User} from "../types";
import {UserContext} from '../App';
import {useContext} from "react";

export const saveToken = (token: string) => localStorage.setItem('access_token', token);

export const getToken = (): string | null => localStorage.getItem('access_token');

export const removeToken = () => localStorage.removeItem('access_token');

export const saveUser = (user: User) => localStorage.setItem('user', JSON.stringify(user));

export const getUser = (): User | null => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user);
    } else {
        return null;
    }
}

export const removeUser = () => localStorage.removeItem('user');

export const getCurrentUser = async () => {
    const token = getToken();

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/account`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + getToken(),
            },
        });

        if (response.ok) {
            return response.json();
        } else {
            removeToken();
        }
    } catch (error) {
        console.log("Ein Fehler ist aufgetreten.")
    }
};

//
// export const useProtectedRoute = (roleRequired: string) => {
//     const navigate = useNavigate();
//
//     return async () => {
//         const user = await getCurrentUser();
//         if (!user || user.role !== roleRequired) {
//             navigate('/login');
//         }
//     };
// };