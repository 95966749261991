import React, {useContext, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {saveToken, saveUser} from "../utils/authentication";
import {UserContext} from "../App";

interface LoginFormValues {
    email: string;
    password: string;
    rememberMe: boolean;
}

const Login: React.FC = () => {
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm<LoginFormValues>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const {setCurrentUser, setCurrentUserToken} = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit: SubmitHandler<LoginFormValues> = async (data) => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    username: data.email,
                    password: data.password,
                    remember_me: data.rememberMe ? 'true' : 'false',
                }),
            });

            if (response.ok) {
                const data = await response.json();
                saveToken(data.access_token);
                saveUser(data.user);
                setCurrentUserToken(data.access_token);
                setCurrentUser(data.user);

                navigate('/content-creator');
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Falsche E-Mail oder Passwort.");
            }
        } catch (error) {
            setErrorMessage('Ein Fehler ist aufgetreten.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>}

            <div>
                <input
                    type="email"
                    placeholder="E-Mail"
                    {...register("email", {required: true})}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
                {errors.email && <p className="my-2 text-red-500 text-sm">E-Mail ist erforderlich.</p>}
            </div>

            <div>
                <input
                    type="password"
                    placeholder="Passwort"
                    {...register("password", {required: true})}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
                {errors.password && <p className="my-2 text-red-500 text-sm">Passwort ist erforderlich.</p>}
            </div>

            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <input
                        id="rememberMe"
                        type="checkbox"
                        {...register('rememberMe')}
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-0"
                    />
                    <label htmlFor="rememberMe" className="ml-2 block text-sm text-gray-900">
                        Angemeldet bleiben
                    </label>
                </div>

                <div className="text-sm leading-6">
                    <a href="/password-reset"
                       className="font-semibold text-blue-600 hover:text-blue-500">
                        Passwort vergessen?
                    </a>
                </div>
            </div>

            <div>
                <button
                    type="submit"
                    className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                >
                    {loading ? (
                        <div className="flex space-x-5">
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                        </div>
                    ) : (
                        "anmelden"
                    )}
                </button>
            </div>
        </form>
    );
};

export default Login;
