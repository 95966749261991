import React, {useEffect, useState} from "react";
import WorkflowItem from "../components/WorkflowItem";
import {Link} from "react-router-dom";
import {Workflow} from "../types";
import {PlusIcon} from "@heroicons/react/16/solid";
import {Simulate} from "react-dom/test-utils";
import {getToken} from "../utils/authentication";

const Workflows: React.FC = () => {
    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/workflows",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setWorkflows(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, []);

    if (loading) {
        return (
            <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
        );
    }

    if (errorMessage) {
        return (
            <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>
        );
    }

    return (
        <div>
            <div className="flex justify-between">
                <div>
                    <h2 className="text-lg font-semibold text-left mb-4 leading-6 text-gray-900">
                        Workflows verwalten
                    </h2>
                    <p className="mt-1 text-sm font-medium text-left text-gray-500">
                        Workflows eignen sich dazu, um bestimmte Anwendungsfälle
                        vorzudefinieren
                    </p>
                </div>
                <Link to="/workflows/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600">
                        <PlusIcon className="w-4 h-4 mr-1"></PlusIcon>
                        Workflow erstellen
                    </button>
                </Link>
            </div>

            <hr className="z-10"/>

            {workflows?.map((workflow, i) => <WorkflowItem {...workflow} key={i}/>)}
        </div>
    );
};

export default Workflows;
