import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import MessageItem from "../components/MessageItem";
import {BookmarkIcon, PaperAirplaneIcon} from "@heroicons/react/16/solid";
import {Message, Role, Workflow} from "../types";
import KnowledgeBaseItem from "../components/KnowledgeBaseItem";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";
import {getToken} from "../utils/authentication";
import {UserContext} from "../App";

const sendMessage = async (
    session_id: string,
    knowledge_base_id: string,
    limit_llm_knowledge: boolean,
    messages: { role: string; content: string }[],
) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + "/content-creator",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                    "Cache-Control": "no-cache",
                    Connection: "keep-alive",
                },
                body: JSON.stringify({
                    session_id,
                    knowledge_base_id,
                    messages,
                    limit_llm_knowledge,
                }),
            },
        );
        if (response.ok) {
            const data = await response.json();
            console.log("Antwort erhalten:", data);
            return [data.answer, data.sources];
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Abrufen der Dokumente:", error);
    }
};

const archiveConversation = async (
    selectedKnowledgeBase: any,
    messages: { role: string; content: string }[],
) => {
    try {
        const currentDate = new Date().toLocaleString("de-DE");

        const archiveTitle = selectedKnowledgeBase
            ? `Konversation | ${selectedKnowledgeBase.title} | ${currentDate}`
            : `Konversation | ${currentDate}`;

        const archiveData = {
            title: archiveTitle,
            history: JSON.stringify(messages),
            sharedWithTenant: false,
        };

        const response = await fetch(process.env.REACT_APP_API_URL + "/archives", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + getToken(),
            },
            body: JSON.stringify(archiveData),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Archivierung erfolgreich:", data);
            return data;
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Archivieren der Konversation:", error);
    }
};

const ContentCreator: React.FC = () => {
    const navigate = useNavigate();
    const conversationEndRef = useRef<HTMLDivElement>(null);

    const [sessionId, setSessionId] = useState<string>(uuidv4());
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string>("");
    const [workflows, setWorkflows] = useState<Workflow[]>([]);

    const [knowledgeBases, setKnowledgeBases] = useState<any[]>([]);
    const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState<any>(null);

    const [limitLLMKnowledge, setLimitLLMKnowledge] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const {currentUser} = useContext(UserContext);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/workflows",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setWorkflows(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, []);

    useEffect(() => {
        const fetchKnowledgeBases = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/knowledgebases",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setKnowledgeBases(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchKnowledgeBases();
    }, []);

    useEffect(() => {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
    }, []);

    const handleWorkflow = async (workflow: Workflow) => {
        const newMessages = [
            ...messages,
            {
                role: "system",
                content: workflow.context + "\n \n" + workflow.action,
                sources: [],
            },
        ];

        setMessages(newMessages);
        conversationEndRef.current?.scrollIntoView({behavior: "smooth"});
        setLoading(true);

        const response = await sendMessage(
            sessionId,
            selectedKnowledgeBase?.id,
            limitLLMKnowledge,
            newMessages,
        );
        handleResponse(response);
    };

    const handleMessage = async () => {
        if (userInput === "") return;

        const newMessages = [
            ...messages,
            {role: "user", content: userInput, sources: []},
        ];

        setMessages(newMessages);
        conversationEndRef.current?.scrollIntoView({behavior: "smooth"});
        setUserInput("");
        setLoading(true);

        const response = await sendMessage(
            sessionId,
            selectedKnowledgeBase?.id,
            limitLLMKnowledge,
            newMessages,
        );
        handleResponse(response);
    };

    const handleResponse = (response: any) => {
        if (response) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {role: "assistant", content: response[0], sources: response[1]},
            ]);
            conversationEndRef.current?.scrollIntoView({behavior: "smooth"});

            setLoading(false);
        }
    };

    const handleKnowledgeBaseChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const selectedId = event.target.value;
        const selectedKB = knowledgeBases.find((kb) => kb.id === selectedId);
        setSelectedKnowledgeBase(selectedKB);
    };

    const handleLimitLLMKnowledgeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setLimitLLMKnowledge(event.target.checked);
    };

    const handleArchiveClick = async () => {
        const archivedData = await archiveConversation(
            selectedKnowledgeBase,
            messages,
        );
        if (archivedData) {
            setMessages([]);
            setUserInput("");
            setSessionId(uuidv4());
            navigate("/archives");
        }
    };

    return (
        <>
            <div>
                <h2 className="text-lg font-semibold text-center mx-2 mb-4 leading-6 text-gray-900">
                    Domänenwissen wählen
                </h2>
                <p className="mt-1 mb-6 text-sm font-medium text-center text-gray-500">
                    Das ausgewählte Domänenwissen steht der AI zur Verfügung
                </p>
                <div className="mt-4 flex row space-x-4">
                    <select
                        id="knowledgeBase"
                        name="knowledgeBase"
                        className="w-full p-3 font-medium text-blue-600 border-b rounded-xl border-r-12 border-white appearance-none bg-none"
                        onChange={handleKnowledgeBaseChange}
                    >
                        <option value="">Knowledge Base auswählen</option>
                        {knowledgeBases.map((knowledgeBase) => (
                            <option key={knowledgeBase.id} value={knowledgeBase.id}>
                                {knowledgeBase.title}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedKnowledgeBase && (
                    <>
                        <KnowledgeBaseItem {...selectedKnowledgeBase} edit_mode={false}/>
                        <fieldset>
                            <div className="mt-6">
                                <div className="relative flex justify-center">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="limit-llm-knowledge"
                                            aria-describedby="limit-llm-knowledge-description"
                                            name="limit-llm-knowledge"
                                            type="checkbox"
                                            className="form-checkbox h-5 w-5 rounded text-blue-600 border-none focus:ring-0 focus:ring-offset-0"
                                            onChange={handleLimitLLMKnowledgeChange}
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label
                                            htmlFor="limit-llm-knowledge"
                                            className="font-medium text-gray-900"
                                        >
                                            AI auf Domänenwissen beschränken
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </>
                )}

                <hr className="z-10"/>
            </div>

            <div>
                <h2 className="text-lg font-semibold text-center mx-2 mb-4 leading-6 text-gray-900">
                    Konversation
                </h2>
                <p className="mt-1 text-sm font-medium text-center text-gray-500">
                    Für vordefinierte Anwendungsfälle können Workflows genutzt werden
                </p>
                <div className="mt-6 mb-72">
                    <div>
                        {messages?.map(
                            (message, i) =>
                                message.role !== "system" && (
                                    <MessageItem {...message} key={i}/>
                                ),
                        )}
                    </div>

                    {messages?.length > 2 && (
                        <button
                            className="flex justify-center items-center mx-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-blue-600 hover:text-blue-700 font-medium cursor-pointer"
                            onClick={handleArchiveClick}
                        >
                            <BookmarkIcon className="w-4 h-4 mr-2"></BookmarkIcon>
                            archivieren und beenden
                        </button>
                    )}
                </div>
                <div ref={conversationEndRef}/>
            </div>

            <div className="fixed bottom-0 left-0 right-0 flex justify-center">
                <div className="py-4 px-3 backdrop-blur-md bg-gray-200/50 w-full max-w-screen-md rounded-xl">
                    <div className="w-full flex flex-wrap justify-start">
                        {workflows?.map(
                            (workflow, i) =>
                                (workflow.visibleForCreator && !workflow.isDisabled && (!workflow.isTemplate || currentUser?.role === Role.SuperAdmin)) && (
                                    <div
                                        onClick={() => handleWorkflow(workflow)}
                                        className={`py-2 px-3 mx-1 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer ${
                                            workflow.isTemplate ? 'hover:bg-gray-600 bg-gray-500' : 'hover:bg-blue-700 bg-blue-600'
                                        }`}
                                        key={i}
                                    >
                                        {workflow.title}
                                    </div>
                                ),
                        )}
                    </div>

                    <form>
                        <div
                            className={`flex items-center py-2 rounded-xl ${
                                loading ? "bg-gray-300" : "bg-white"
                            } mt-2`}
                        >
              <textarea
                  id="chat"
                  value={userInput}
                  className="block mx-2 p-2.5 w-full h-26 text-md text-gray-900 bg-transparent rounded-xl font-medium border-none focus:ring-0 focus:ring-offset-0"
                  placeholder="Prompt …"
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={(e) => {
                      if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
                          handleMessage();
                      }
                  }}
                  disabled={loading}
              ></textarea>
                            <div
                                className="inline-flex justify-center p-2 mr-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100">
                                {loading ? (
                                    <span
                                        className="animate-ping mr-1 w-7 h-7 rounded-full bg-blue-600 opacity-60"></span>
                                ) : (
                                    <PaperAirplaneIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                        onClick={handleMessage}
                                    />
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContentCreator;
