import React, {useContext, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {getCurrentUser, getToken} from "../utils/authentication";
import {Role, User} from "../types";
import {UserContext} from "../App";

interface CreateEditWorkflowProps {
    action: "create" | "edit";
}

interface WorkflowFormValues {
    title: string;
    context: string;
    action: string;
    visibleForCreator: boolean;
    visibleForConverter: boolean;
    sharedWithTenant: boolean;
    isTemplate: boolean | null;
    isDisabled: boolean;
}

const WorkflowCreateEdit: React.FC<CreateEditWorkflowProps> = ({action}) => {
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<WorkflowFormValues>();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const {currentUser} = useContext(UserContext);

    useEffect(() => {
        const fetchWorkflow = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + `/workflows/${id}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setValue("title", data.title);
                    setValue("context", data.context);
                    setValue("action", data.action);
                    setValue("visibleForCreator", data.visibleForCreator);
                    setValue("visibleForConverter", data.visibleForConverter);
                    setValue("sharedWithTenant", data.sharedWithTenant);
                    setValue("isTemplate", data.isTemplate);
                    setValue("isDisabled", data.isDisabled);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        if (action === "edit" && id) {
            fetchWorkflow();
        }
    }, [action, id, setValue]);

    const onSubmit: SubmitHandler<WorkflowFormValues> = async (data) => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            const method = action === "create" ? "POST" : "PUT";
            const url =
                action === "create"
                    ? process.env.REACT_APP_API_URL + "/workflows"
                    : process.env.REACT_APP_API_URL + `/workflows/${id}`;

            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setSuccessMessage(
                    action === "create"
                        ? "Workflow erfolgreich erstellt!"
                        : "Workflow erfolgreich aktualisiert!",
                );
                navigate("/workflows");
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setSuccessMessage(null);
        setErrorMessage(null);
        setLoading(true);

        const confirmDelete = window.confirm(
            "Soll der Workflow wirklich gelöscht werden?",
        );
        if (!confirmDelete) {
            return;
        }

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + `/workflows/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                }
            );

            if (response.ok) {
                setSuccessMessage("Workflow erfolgreich gelöscht!");
                navigate("/workflows");
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        }
    };

    return (
        <div>
            <div className="flex justify-center mx-auto">
                <Link to="/workflows" className="inline-block">
                    <button className="flex mb-8 rounded-md text-sm font-medium leading-6 text-gray-500">
                        <ChevronLeftIcon className="h-6 w-6 items-center mr-1"></ChevronLeftIcon>
                        zurück
                    </button>
                </Link>
            </div>

            <form
                className="border-b rounded-xl bg-white p-5"
                onSubmit={handleSubmit(onSubmit)}
            >
                {successMessage && (
                    <div className="mb-4 text-green-500 text-sm">{successMessage}</div>
                )}
                {errorMessage && (
                    <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>
                )}

                <div className="mb-4">
                    <label
                        htmlFor="title"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Titel
                    </label>
                    <div className="mt-2">
                        <input
                            id="title"
                            {...register("title", {required: true})}
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                        {errors.title && (
                            <p className="mt-2 text-red-500 text-sm">
                                Dieses Feld ist erforderlich.
                            </p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="context"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Kontext
                    </label>
                    <div className="mt-2">
            <textarea
                id="context"
                {...register("context", {required: true})}
                rows={5}
                aria-describedby="context-description"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
                        {errors.context && (
                            <p className="mt-2 text-red-500 text-sm">
                                Dieses Feld ist erforderlich.
                            </p>
                        )}
                    </div>
                    <p id="context-description" className="mt-2 text-sm text-gray-500">
                        Welcher Kontext ist für die AI zusätzlich nötig?
                    </p>
                </div>

                <div className="mb-6">
                    <label
                        htmlFor="action"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Aktion
                    </label>
                    <div className="mt-2">
            <textarea
                id="action"
                {...register("action", {required: true})}
                rows={5}
                aria-describedby="action-description"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            />
                        {errors.action && (
                            <p className="mt-2 text-red-500 text-sm">
                                Dieses Feld ist erforderlich.
                            </p>
                        )}
                    </div>
                    <p id="action-description" className="mt-2 text-sm text-gray-500">
                        Wie soll die Interaktion mit der AI ablaufen?
                    </p>
                </div>

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="visibleForCreator"
                                {...register("visibleForCreator")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label
                                htmlFor="visibleForCreator"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Sichtbar bei Content Creator
                            </label>
                        </div>
                    </div>
                </div>

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="visibleForConverter"
                                {...register("visibleForConverter")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label
                                htmlFor="visibleForConverter"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Sichtbar bei Content Converter
                            </label>
                        </div>
                    </div>
                </div>

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="sharedWithTenant"
                                {...register("sharedWithTenant")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label
                                htmlFor="sharedWithTenant"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Mit allen Benutzern des Tenants <span
                                className="mx-1 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">{currentUser?.tenant.name}</span> teilen
                            </label>
                        </div>
                    </div>
                </div>

                {(currentUser?.role === Role.SuperAdmin &&
                    <div className="my-6">
                        <div className="relative flex ml-2">
                            <div className="flex h-6 items-center">
                                <input
                                    id="isTemplate"
                                    {...register("isTemplate")}
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6 flex">
                                <label
                                    htmlFor="isTemplate"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Template Workflow
                                </label>
                                <span
                                    className="mx-2 inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">SuperAdmin Privileg</span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="isDisabled"
                                {...register("isDisabled")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6 flex">
                            <label
                                htmlFor="isDisabled"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Workflow deaktivieren
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            action === "create" ? "erstellen" : "aktualisieren"
                        )}
                    </button>
                </div>
            </form>
            {action === "edit" && (
                <div className="mt-8">
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="flex justify-center mx-auto rounded-md text-sm font-medium leading-6 text-red-600"
                    >
                        Workflow löschen
                    </button>
                </div>
            )}
        </div>
    );
};

export default WorkflowCreateEdit;
