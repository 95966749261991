import React, {useContext, useEffect, useState} from "react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Disclosure} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {ChatBubbleBottomCenterTextIcon, ChevronRightIcon, Cog6ToothIcon, UserIcon} from "@heroicons/react/16/solid";
import {removeToken, removeUser} from "../utils/authentication";
import {classNames} from "../utils/utils";
import {UserContext} from "../App";
import {Role} from "../types";

const MainLayout: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {currentUser, setCurrentUser, currentUserToken, setCurrentUserToken} = useContext(UserContext);

    const [navigation, setNavigation] = useState([
        {
            title: "Content Creator",
            subtitle: "Contents schrittweise entwickeln",
            href: "/content-creator",
            current: false,
            hidden: false,
        },
        {
            title: "Content Converter",
            subtitle: "Dokumente in Kurse konvertieren",
            href: "/content-converter",
            current: false,
            hidden: false,
        },
        {
            title: "Knowledge Bases",
            subtitle: "Domänenwissen für Content Creator",
            href: "/knowledgebases",
            current: false,
            hidden: false,
        },
        {
            title: "Workflows",
            subtitle: "Automatisierung durch vordefinierte Abläufe",
            href: "/workflows",
            current: false,
            hidden: false
        },
        {title: "Archive", subtitle: "vergangene Konversationen", href: "/archives", current: false, hidden: false},
        {title: "Account Einstellungen", subtitle: "", href: "/account", current: false, hidden: true},
    ]);

    useEffect(() => {
        setNavigation((prevNavigation) =>
            prevNavigation.map((item) => ({
                ...item,
                current: location.pathname.startsWith(item.href),
            })),
        );

        window.scrollTo(0, 0);
    }, [location]);

    const currentNavItem = navigation.find((item) => item.current);

    const handleLogout = () => {
        removeToken();
        removeUser();
        setCurrentUser(undefined);
        setCurrentUserToken('');
        navigate('/login');
    };

    return (
        <div className="min-h-full">
            <div className="fixed top-0 w-full z-50">
                <Disclosure as="nav" className="bg-gray-800">
                    {({open}) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <Link to="/content-creator">
                                            <div className="flex">
                                                <ChatBubbleBottomCenterTextIcon
                                                    className="h-6 w-6 text-blue-500"
                                                    aria-hidden="true"
                                                />
                                                <span
                                                    className="px-3 text-base font-bold text-blue-500">Content AI</span>
                                            </div>
                                        </Link>

                                        <div className="hidden lg:block">
                                            <div className="ml-10 flex items-baseline space-x-6">
                                                {navigation.map(
                                                    (item) =>
                                                        !item.hidden && (
                                                            <Link to={item.href} key={item.title}>
                                                                <button
                                                                    className={classNames(
                                                                        item.current
                                                                            ? "bg-gray-900 text-white"
                                                                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                                        "rounded-md px-3 py-2 text-sm font-medium",
                                                                    )}
                                                                    aria-current={
                                                                        item.current ? "page" : undefined
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </button>
                                                            </Link>
                                                        ),
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden lg:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            <Link to="/account">
                                                <button
                                                    type="button"
                                                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white"
                                                >
                                                    <span className="absolute -inset-1.5"/>
                                                    <UserIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </Link>
                                            <button
                                                onClick={handleLogout}
                                                type="button"
                                                className="ml-4 relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white"
                                            >
                                                <ChevronRightIcon className="h-7 w-7" aria-hidden="true"/>
                                            </button>
                                            {currentUser?.role === Role.SuperAdmin &&
                                                <Link to="/settings">
                                                    <button
                                                        type="button"
                                                        className="ml-4 relative rounded-full p-1 text-blue-500 hover:text-white"
                                                    >
                                                        <span className="absolute -inset-1.5"/>
                                                        <Cog6ToothIcon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex lg:hidden">
                                        <Disclosure.Button
                                            className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white">
                                            <span className="absolute -inset-0.5"/>
                                            {open ? (
                                                <XMarkIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <Bars3Icon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="lg:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    {navigation.map((item) => (
                                        !item.hidden && (
                                            <Link to={item.href} key={item.title}>
                                                <Disclosure.Button
                                                    as="button"
                                                    className={classNames(
                                                        item.current
                                                            ? "bg-gray-900 text-white"
                                                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                        "block rounded-md px-3 py-2 text-base font-medium",
                                                    )}
                                                    aria-current={item.current ? "page" : undefined}
                                                >
                                                    {item.title}
                                                </Disclosure.Button>
                                            </Link>
                                        )
                                    ))}
                                    <Link to="/account">
                                        <Disclosure.Button>
                                            <button
                                                type="button"
                                                className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white ml-2"
                                            >
                                                <span className="absolute -inset-1.5"/>
                                                <UserIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </Disclosure.Button>
                                    </Link>
                                    <Disclosure.Button>
                                        <button
                                            onClick={handleLogout}
                                            type="button"
                                            className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white ml-2"
                                        >
                                            <ChevronRightIcon className="h-7 w-7" aria-hidden="true"/>
                                        </button>
                                    </Disclosure.Button>
                                    <Link to="/settings">
                                        <Disclosure.Button>
                                            <button
                                                type="button"
                                                className="relative rounded-full p-1 text-blue-500 hover:text-white ml-2"
                                            >
                                                <span className="absolute -inset-1.5"/>
                                                <Cog6ToothIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </Disclosure.Button>
                                    </Link>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                <header className="bg-white border-b-2">
                    <div className="mx-auto max-w-3xl px-3 py-6">
                        <h1 className="text-2xl text-gray-900 text-left m-0">
                            {currentNavItem?.title || ""}
                            {currentNavItem?.subtitle &&
                                <span
                                    className="mx-4 inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">{currentNavItem?.subtitle}</span>
                            }
                        </h1>

                    </div>
                </header>
            </div>
            <main className="mt-36">
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                    <div className="max-w-screen-md mx-auto mt-4">
                        <div className="min-h-full flex flex-col justify-center m-3">
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MainLayout;
