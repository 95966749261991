import React, {useContext, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {getCurrentUser, getToken, saveUser} from "../utils/authentication";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {Role, Tenant, User} from "../types";
import tenants from "./Tenants";
import {UserContext} from "../App";
import workflows from "./Workflows";

interface AccountFormValues {
    first_name: string;
    last_name: string;
    email: string;
    password: string | null;
    new_password: string | null;
}

const Account: React.FC = () => {
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<AccountFormValues>();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {setCurrentUser, setCurrentUserToken} = useContext(UserContext);
    const [tenantUsers, setTenantUsers] = useState<User[]>([]);


    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                setValue("first_name", currentUser.first_name);
                setValue("last_name", currentUser.last_name);
                setValue("email", currentUser.email);
            } else {
                setErrorMessage("Benutzer konnte nicht geladen werden.");
            }
        };

        const fetchTenantUsers = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "/tenants/" + currentUser?.tenant.id + "/users", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTenantUsers(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
        fetchTenantUsers();
    }, [setValue]);

    const onSubmit: SubmitHandler<AccountFormValues> = async (data) => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/account`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const data = await response.json();
                saveUser(data);
                setCurrentUser(data);
                setSuccessMessage("Account erfolgreich aktualisiert!");
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <form className="border-b rounded-xl bg-white p-5" onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-base font-bold leading-6 text-gray-900 mb-6">Account</h2>

                {successMessage && <div className="mb-4 text-green-500 text-sm">{successMessage}</div>}
                {errorMessage && <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>}

                <div className="mb-4">
                    <label htmlFor="first_name"
                           className="block text-sm font-medium leading-6 text-gray-900">Vorname</label>
                    <div className="mt-2">
                        <input
                            id="first_name"
                            {...register("first_name", {required: true})}
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                        />
                        {errors.first_name && <p className="mt-2 text-red-500 text-sm">Vorname ist erforderlich.</p>}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="last_name"
                           className="block text-sm font-medium leading-6 text-gray-900">Nachname</label>
                    <div className="mt-2">
                        <input
                            id="last_name"
                            {...register("last_name", {required: true})}
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                        />
                        {errors.last_name && <p className="mt-2 text-red-500 text-sm">Nachname ist erforderlich.</p>}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">E-Mail</label>
                    <div className="mt-2">
                        <input
                            id="email"
                            {...register("email", {required: true})}
                            type="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                        />
                        {errors.email && <p className="mt-2 text-red-500 text-sm">E-Mail ist erforderlich.</p>}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="password"
                           className="block text-sm font-medium leading-6 text-gray-900">Passwort</label>
                    <div className="mt-2">
                        <input
                            id="password"
                            {...register("password")}
                            type="password"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                        />
                        {errors.password && <p className="mt-2 text-red-500 text-sm">Passwort ist ungültig.</p>}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="new_password" className="block text-sm font-medium leading-6 text-gray-900">Neues
                        Passwort</label>
                    <div className="mt-2">
                        <input
                            id="new_password"
                            {...register("new_password", {minLength: 8})}
                            type="password"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                        />
                        {errors.new_password &&
                            <p className="mt-2 text-red-500 text-sm">Passwort muss mindestens 8 Zeichen lang sein.</p>}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">Rolle</label>
                    <input
                        id=""
                        type="text"
                        value={currentUser?.role || ""}
                        readOnly
                        className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                    />
                </div>

                <div className="mt-5">
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            "aktualisieren"
                        )}
                    </button>
                </div>
            </form>

            <div className="border-b rounded-xl bg-white p-5 mt-6">
                <h2 className="text-base font-bold leading-6 text-gray-900 mb-6">Tenant Infos</h2>
                <div className="mb-4">
                    <label htmlFor="tenant_id"
                           className="block text-sm font-medium leading-6 text-gray-900">Tenant Name</label>
                    <input
                        id="tenant_id"
                        type="text"
                        value={currentUser?.tenant.name || ""}
                        readOnly
                        className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                    />
                </div>
                <span className="block text-sm font-medium leading-6 text-gray-900 ">Benutzer</span>
                <div className="w-full flex flex-wrap justify-start mt-2">
                    {tenantUsers?.map(
                        (user, i) =>
                            <div
                                className="py-2 px-3 mr-2 mb-2 rounded-lg text-sm text-white font-medium cursor-pointer bg-gray-400"
                                key={i}
                            >
                                {user.first_name} {user.last_name}
                            </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Account;
