import React, {useEffect, useState} from "react";
import ArchiveItem from "../components/ArchiveItem";
import {Archive} from "../types";
import {getToken} from "../utils/authentication";

const Archives: React.FC = () => {
    const [archives, setArchives] = useState<Archive[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const fetchArchives = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/archives",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setArchives(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchArchives();
    }, []);

    if (loading) {
        return (
            <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
        );
    }

    if (errorMessage) {
        return (
            <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>
        );
    }

    return (
        <div>
            <div className="flex justify-between">
                <div>
                    <h2 className="text-lg font-semibold text-left mb-4 leading-6 text-gray-900">
                        Archive verwalten
                    </h2>
                    <p className="mt-1 text-sm font-medium text-left text-gray-500">
                        Archive speichern vergangene Konversationen
                    </p>
                </div>
            </div>

            <hr className="z-10"/>

            {archives?.map((archive, i) => <ArchiveItem {...archive} key={i}/>)}
        </div>
    );
};

export default Archives;
