import React from "react";
import ReactMarkdown from "react-markdown";

import "./MessageItem.css";
import {Message} from "../types";

const MessageItem: React.FC<Message> = ({role, content, sources}) => {
    return (
        <div className={`${role}-message message mt-4`}>
            <div className="flex flex-col w-full leading-1.5 px-4 rounded-xl rounded-es-xl border border-blue-600/30">
                <ReactMarkdown className="markdown">{content}</ReactMarkdown>
                {sources?.length > 0 && (
                    <div className="w-full flex flex-wrap mt-2">
                        {sources?.map((source, i) => (
                            <div
                                className="mb-4 mr-2 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-500 ring-1 ring-inset ring-blue-600/20"
                                key={i}
                            >
                                {source}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <span className="font-semibold text-sm m-2">
        {role === "assistant"
            ? "AI"
            : role === "user"
                ? "Ich"
                : role.slice(0, 1).toUpperCase() + role.slice(1)}
      </span>
        </div>
    );
};

export default MessageItem;
