import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {KnowledgeBase} from "../types";
import {PlusIcon} from "@heroicons/react/16/solid";
import KnowledgeBaseItem from "../components/KnowledgeBaseItem";
import {getToken} from "../utils/authentication";

const KnowledgeBases: React.FC = () => {
    const [knowledgeBases, setKnowledgebases] = useState<KnowledgeBase[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const fetchKnowledgeBases = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/knowledgebases",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setKnowledgebases(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchKnowledgeBases();
    }, []);

    if (loading) {
        return (
            <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
        );
    }

    if (errorMessage) {
        return (
            <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>
        );
    }

    return (
        <div>
            <div className="flex justify-between">
                <div>
                    <h2 className="text-lg font-semibold text-left mb-4 leading-6 text-gray-900">
                        Knowledge Bases verwalten
                    </h2>
                    <p className="mt-1 text-sm font-medium text-left text-gray-500">
                        Knowledge Bases bilden das Domänenwissen aus Dokumenten ab
                    </p>
                </div>
                <Link to="/knowledgebases/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600">
                        <PlusIcon className="w-4 h-4 mr-1"></PlusIcon>
                        Knowledge Base erstellen
                    </button>
                </Link>
            </div>

            <hr className="z-10"/>

            {knowledgeBases?.map((knowledgeBase, i) => (
                <KnowledgeBaseItem {...knowledgeBase} key={i} edit_mode={true}/>
            ))}
        </div>
    );
};

export default KnowledgeBases;
