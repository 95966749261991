import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {getToken} from "../utils/authentication";

interface PasswordResetFormValues {
    email: string;
}

const PasswordReset: React.FC = () => {
    const {register, handleSubmit, formState: {errors}, watch, reset} = useForm<PasswordResetFormValues>();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit: SubmitHandler<PasswordResetFormValues> = async (data) => {
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/password-reset`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setSuccessMessage("Weitere Anweisungen wurden per E-Mail gesendet!");
                reset();
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            {successMessage && <div className="mb-4 text-green-500 text-sm">{successMessage}</div>}
            {errorMessage && <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>}

            <div>
                <input
                    type="email"
                    placeholder="E-Mail"
                    {...register("email", {required: true})}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
                {errors.email && <p className="my-2 text-red-500 text-sm">E-Mail ist erforderlich.</p>}
            </div>

            <div>
                <button
                    type="submit"
                    className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                >
                    {loading ? (
                        <div className="flex space-x-5">
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                        </div>
                    ) : (
                        "Passwort zurücksetzen"
                    )}
                </button>
            </div>
        </form>
    );
};

export default PasswordReset;
