import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {getToken} from "../utils/authentication";

interface TenantFormValues {
    name: string;
}

interface TenantCreateEditProps {
    action: "create" | "edit";
}

const TenantCreateEdit: React.FC<TenantCreateEditProps> = ({action}) => {
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<TenantFormValues>();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (action === "edit" && id) {
            const fetchTenant = async () => {
                try {
                    const response = await fetch(process.env.REACT_APP_API_URL + `/tenants/${id}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setValue("name", data.name);
                    } else {
                        const data = await response.json();
                        setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                    }
                } catch (error) {
                    setErrorMessage("Fehler beim Laden des Tenants.");
                }
            };

            fetchTenant();
        }
    }, [action, id, setValue]);

    const onSubmit: SubmitHandler<TenantFormValues> = async (data) => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            const method = action === "create" ? "POST" : "PUT";
            const url = action === "create"
                ? process.env.REACT_APP_API_URL + "/tenants"
                : process.env.REACT_APP_API_URL + `/tenants/${id}`;

            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setSuccessMessage(
                    action === "create"
                        ? "Tenant erfolgreich erstellt!"
                        : "Tenant erfolgreich aktualisiert!"
                );
                navigate("/tenants");
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="flex justify-center mx-auto">
                <Link to="/tenants" className="inline-block">
                    <button className="flex mb-8 rounded-md text-sm font-medium leading-6 text-gray-500">
                        <ChevronLeftIcon className="h-6 w-6 items-center mr-1"/>
                        zurück
                    </button>
                </Link>
            </div>

            <form className="border-b rounded-xl bg-white p-5" onSubmit={handleSubmit(onSubmit)}>
                {successMessage && <div className="mb-4 text-green-500 text-sm">{successMessage}</div>}
                {errorMessage && <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>}

                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                    <div className="mt-2">
                        <input
                            id="name"
                            {...register("name", {required: true})}
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                        />
                        {errors.name && <p className="mt-2 text-red-500 text-sm">Name ist erforderlich.</p>}
                    </div>
                </div>
                <div className="mt-5">
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            action === "create" ? "erstellen" : "aktualisieren"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TenantCreateEdit;
