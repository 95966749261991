export function getExtension(filename: String): string {
    return filename.split(".").pop()?.toUpperCase() ?? "";
}

export function classNames(...classes: string[]): string {
    return classes.filter(Boolean).join(" ");
}

export function formatDate(date: string): string {
    return new Date(date).toLocaleDateString("de-DE");
}

export function formatDateTime(date: string): string {
    return new Date(date).toLocaleString("de-DE", {
        dateStyle: "short",
        timeStyle: "short",
    });
}